/** To import custom fonts (ie. from google): */
/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap'); */

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeout {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes blinking {
    from,
    to {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
}

:root {
    --main-color: #ff5500;
    --error-color: #e0360b;

    --scrollbar-color: #323232;
    --bg-color: #101010;

    --toastify-spinner-color: var(--main-color) !important;
    --toastify-spinner-color-empty-area: transparent !important;

    --toastify-icon-color-info: var(--main-color) !important;
    --toastify-color-progress-info: var(--main-color) !important;

    --toastify-icon-color-error: var(--error-color) !important;
    --toastify-color-progress-error: var(--error-color) !important;
}

.Toastify__black-background {
    background-color: #1c1c1c !important;
    color: #ccc !important;
}
.Toastify__white-background {
    background-color: #eee !important;
    color: #1c1c1c !important;
}

.Toastify__close-button--light {
    color: #ccc !important;
}

/* ::-webkit-scrollbar {
    width: 11px;
    height: 11px;
}
::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 6px;
}
::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-color);
    border-radius: 6px;
    border: 3px solid var(--bg-color);
} */

/* --- Player Skill Informations --- */
.faceit-level {
    background: no-repeat center;
    background-image: url("../../public/images/faceit_levels/0.svg");
    background-size: contain;
    height: 32px;
    width: 32px;
}
.playerSkillDiv {
    display: flex;
    width: 175px;
    align-items: center;
    /* margin: auto; */
    padding: 4px 8px 4px 12px;
    border-radius: 6px;
    color: #888;
    /* background-color: #1f1f1f; */
    background-color: #1a1a1a;
}
@media (max-width: 991.98px) {
    /* mobile */
    /* #info-skill {
      justify-content: center;
      margin: 10px 0 25px; /* fix flex 50% */
    /* }
  .playerSkillDiv {
      display: flex;
      width: 156px;
      flex: none;
      align-items: center;
      margin: auto;
      margin-top: 16px !important;
      padding: 4px 8px 4px 12px;
      border-radius: 6px;
      color: #888;
      /* background-color: #1f1f1f; */
    /* background-color: #1a1a1a;
      transform: scale(1.5);
  } */
}
.playerSkillDiv .playerElo * {
    display: inline-block;
    vertical-align: middle;
}
.playerSkillDiv .elo-icon {
    background: no-repeat center;
    background-image: url("../../public/images/elo_icon.svg");
    background-size: contain;
    height: 19px;
    width: 19px;
}
.eloTotal {
    font-size: 16px;
}
.textTransition {
    color: #888;
    transition: linear 100ms;
    cursor: pointer;
}
.textTransition:hover,
.textTransition:focus {
    color: var(--primary);
    transition: linear 100ms;
}

/* loader */
.loader {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #101010;
    transition: opacity 0.3s;
}

.logo {
    width: 80%;
    max-width: 800px;
}

.loader--hide {
    opacity: 0;
}
.loader--displaynone {
    display: none;
}

.panel:hover span {
    visibility: visible;
}

.panel span {
    font-size: 12px;
    pointer-events: none;
    visibility: hidden;
    width: 180px;
    background-color: #5f636b;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 8px 0;
    position: absolute;
    z-index: 1;
    bottom: 175%;
    left: 50%;
    margin-left: -90px;
}

.tooltip::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #5f636b transparent transparent transparent;
}
